import { defineNuxtRouteMiddleware } from '#app'

export default defineNuxtRouteMiddleware(async (to) => {
  const $auth = useAuth()
  const user = await $auth.actions.profile()

  if (!user) {
    return navigateTo(`/?redirect=${to.fullPath}`)
  }
})
